import React from 'react'
import { graphql } from 'gatsby'
import * as styles from '../styles/textOnlyPage.module.scss'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import { PortableText } from '@portabletext/react'

export const query = graphql`
  query textOnlyPages {
    allSanityTextOnlyPage {
        nodes {
            slug {
                current
            }
            title
            _rawTextBlock
        }
    }
  }
`

const TextOnlyPageTemplate = (props) => {
  const { data, pageContext } = props
  const pageData = data.allSanityTextOnlyPage.nodes.find(
    (textOnlyPage) => textOnlyPage.slug.current === pageContext.textOnlySlug.current
  )
  return (
    <>
      <Layout>
        <div className={styles.container}>
            <h1 className={styles.title}>{pageData.title}</h1>
            <div className={styles.textContainer}>
                <PortableText value={pageData._rawTextBlock}/>
            </div>
        </div>
      </Layout>
    </>
  )
}

export default TextOnlyPageTemplate

export const Head = (props) => {
  const { data, pageContext } = props
  let { textOnlySlug } = pageContext
  const pageData = data.allSanityTextOnlyPage.nodes.find(
    (textOnly) => textOnly.slug.current === textOnlySlug.current
  )
  return <Seo title={pageData.browserTitle} seo={pageData.seoData} />
}
